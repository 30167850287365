import React from 'react'
import { useState } from "react";
import Index from './pages/Index';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

function NavButton(props: any) {
  return (
    <li className={"mr-3"}>
      <Link to={props.to} className="bg-green-950 rounded hover:border-gray-200 text-white hover:bg-green-700 py-1 px-3" >{props.text}</Link>
    </li>
  )
}


function MyNavBar() {
  const [activeColor, setActiveColor] = useState("bg-slate-800");
  const [buttonDist] = useState("3")
  return (

    <div className='text-red-300 text-center flex flex-col'>

        <div>
          <ul className="flex justify-center">

            <NavButton to="/" text="Home"></NavButton>

            <NavButton to="/Full-Story" text="Full Story"></NavButton>
            <NavButton to="/AboutMe" text="About Me"></NavButton>
            {/* <li className="mr-3">
                <Link to="" className="bg-green-950 rounded hover:border-gray-200 text-white hover:bg-gray-200 py-1 px-3" >Pill</Link>
              </li> */}

          </ul>
        </div>
        <div className='mt-5'>
          <p className='text-red-300'> I think I made this during some kind of manic episode, so don't think too hard about what I say. I'm leaving it here for documentation.</p>

        </div>
    </div>

  )
}

export default MyNavBar