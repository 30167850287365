import React from 'react'

function Contact() {
  return (
    <div className="bg-slate-800 p-4 px-8  text-3xl rounded-md text-white content-center">
    <h1 className='font-bold'>

      <div>Looking for me?</div> <div className='text-xs'>Me Too!</div>
      <div className='text-xs text-red-800'>Help me find</div>
    </h1>
    <ul className='text-2xl pt-3'>
      <li> <b> <u>Discord:</u></b> <a href='https://discord.gg/MTQSJruqF5'>discord.gg/MTQSJruqF5</a> </li>
      <p className='bg-slate-800 m-5 rounded-md text-sm'>
      
        I'll add more stuff if I keep doing things. I just like to write. <br></br>
      </p>
    </ul>
  </div>
  )
}

export default Contact