import React from 'react';
import logo from './logo.svg';
import Contact from './components/Contact'
import MyNavBar from './components/MyNavBar';
import FourOFour from './components/pages/FourOFour';
// import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Index from './components/pages/Index';
import Story from './components/pages/Story';
import AboutMe from './components/pages/AboutMe';

function App() {
  return (
    <BrowserRouter>

      <div className="pt-1 min-h-screen bg-slate-700 ">

        <div className='m-3 flex justify-center'>
          <MyNavBar></MyNavBar>
        </div>
        <div className='m-3 flex justify-center'>

          <Routes>
            <Route path="/" element={<Index />} />

            <Route path="Full-Story" element={<Story />} />
            <Route path="aboutme" element={<AboutMe />} />
            <Route path="*" element={<FourOFour />} />

          </Routes>
        </div>


        <div className='flex flex-none justify-center'>
          {/* <Contact></Contact> */}
        </div>

        <br></br>
        <p className='text-white'>
        </p>
      </div>


    </BrowserRouter>



  );
}

export default App;
