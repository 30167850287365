import React from 'react'

function AboutMe() {
  //                I once knew a mare who told me near the end of her life that the only way we could practically defend ourselves from a cabal of griffons (who made us pay for the luxury of dishonest truths with pounds of flesh), was with honest lies.

  return (
    <div>
        <div className='text-slate-300 bg-slate-800 rounded-lg p-8'>
            <p>
               For some reason, most people don't believe what I say. They are my closest friends.
               <br></br>That being said...
                <br></br>
                <br></br>
                I once knew a crazy mare who told me near the end of her life that the only way we could fight "the griffin cabals" who "made us pay for the luxury of dishonest truths with pounds of flesh",
                was with lies. Ironically, I think there is some truth to her words, despite them coming from the same mare who said that "the chemicals in Baltimare's water is turning the stallions into femcolts". Fiction, despite being composed of untrue events, often feels more true to our souls than the lives we were born into. I think she was angry at me. I was a newsmare, a master-craftsmare of truths. Now I just do oddjobs, here and there, and maybe a comic for funsies. So long as I may write, there will always be heroes and villains. Most ponies want to be a hero, and very few ponies want to be the <s>scapegoat</s> villain (a true, disgusting villain). Convince a pony that they are one, or even the other, and even with blatant fiction, you'll give them a truth that will, quite literally, put a pony on the moon.
                <br></br>
                <br></br>
                Of course, being the newsmare that I was, I gave her advice on how her poor choice of words would affect the narrative of her battles. I told her that she had to stop using the word "fight". Regardless of whether she was right or wrong, regardless of whether there was a war waged against her kin or not, she could not use words which insinuated that her goals were to <i>initiate</i> a "fight", even if she was the one being eaten alive. She percieved herself as bleeding from the cannibalism, but since she had no scars to show for it, I advised her to play the game as strategically as her agressors. If she continued, she would paint herself as an agressor. Nopony helps the agressor of a fight. She may of as well served herself bound and gagged on their silver plates.
                <br></br>
                <br></br>
                I've never had a pony look so disgusted at me. I think she saw some hope in me at one point. A glimmer of...something beautiful. But she always hated the news, the creatures that owned it; most ponies do. I know why she hates me. It hurts to write. The way I thought of the world. The way I still do. My way of conducting myself and working with other ponies. I mean, I called it "working with other ponies". She called it "manupulating" them. And I said, somewhat jokingly, that <i>she</i> was the one manipulating words to paint *me* as the agressor! I was so smug of my little joke, and thought I had "won the argument", but also, I was deeply fearful that she would not grasp the importance of mastering these word-games to shape the narratives of her battles. But it seemed to be everything she absolutely hated, found disgusting with the world. I think she lost all hope for me at that point.
                <br></br>
                <br></br>
                Why am I writing all these lies? I was a newsmare once upon a time. I threw shat at the wall so often, lies so often, that lying became second nature. Lying became the only way I <b><i>could</i></b> tell the celestia-damned truth! It was part of the game. Telling so many damned lies that nopony would care about the truth. You had to be mad to care about something so insignificant. I'm so sorry. To the only mare mad enough to care about truth. The only mare mad enough to care about a celestia-forsaken newsmare. I'm not just making this a pity-party about me. Whether you know it or not, you watch the news to be entertained by the truth. Let this mare entertain you with something more honest: lies.
                <br></br>
                <br></br>
                There are so many fighters in this world who fight for their truths. Driven by narratives that writers like us would present them. Fed to them by a name among the fifty other pennames some deliverymares of truth carry. MareABC, MareXYZ, or their other-creature three-letter equivalents. At one point in my life, I had convinced so many ponies to die for (or dedicate their lives to, same thing) causes that I myself could never imagine believing in, causes whose narratives that I lead the construction and deployment of, that I had lost the ability to form any personal truths of my own to fight for. I feared to have an essence because I lived in a world where I turned everypony's essence against themselves in the name of profit.
                
                <br></br><br></br>
                And the kicker? I didn't just peddle narratives. I peddled the fervor into the air that if you didn't have one of <i>my</i> narratives to fight for, you were maladjusted, broken, wrong. To dare and exist in the world with any narrative other than one of the ones I had unleashed onto Equestria was an uphill battle. It spread like a brain virus. Grew out of my control. I think I've infected myself with it the worst.
                 Faust, please forgive me. Please. There is no mare mad enough in this world to forgive me. Maybe if I had never been a newsmare, that would not be the truth.
                <br></br>
                <br></br>
                <s>
                You want the truth? Here's the truth, the honest truth, and nothing but the truth...
                <br></br>
                Most of my more personal projects passively push a very well hidden (a *lot* more hidden than what I might post here in the future) narrative that shifts the global conciousness towards veganism and animal welfare. You don't have to believe me. The only mare that I've shown this to became mad! But in many places polluted by my ideas, I have planted the seeds that I'm sure have passively caused a statistically significant amount of griffons to transition towards veganism.
                <br></br>
                The biggest challenge of that phase of my life was keeping the vegans competitively viable against the meat-eaters. A griffon with less nutrition will always lose the game of life to one in better health, after all. What's the point of cauing change if the products, or biproducts, of your change die?
                <br></br>
                <br></br>
                </s>

                Maybe you've kept on reading because you need a newsmare to entertain you with the truth.
                <br></br>
                Truths, that she needs you to believe, <b>truly</b> believe, are lies.
                <br></br><br></br>
                I hope that you will come to find more truth in my lies than you can anywhere else.
                <br></br>
                <s>
                Because this page is a toast to All The Fallen newsmares, who made the mistake of telling the wrong truth.

                </s>
                <br></br>
                <br></br>
                <s>

                What I say next is surprisingly irrelevant to the previous paragraph. I think I've infected myself the worst. But I've managed to keep some of my sanity by shutting the doors to the truths out there that I've created. Plugged my ears and closed my eyes for a long while. I had a dream where I asked Twilight to let me peek at nearby alternative universes, where I had fed on the fruits of truth from seeds I planted long ago. Seeds that blossomed into nations. I was drivel in all of them. These lies are the only thing keeping me alive. The truth wont stop pounding at my door. I'm running out of food, but I don't want to go out there. I'm choking on stale air. The monster I made should be the one trapped in this cage, not me. Am I the monster? I'm the one in the cage, after all. Ironic. I infected everypony else with those truths, but would not infect myself. And then there was one. Quarantined in here.
                </s>

            </p>
        </div>

       

    </div>
  )
}

export default AboutMe