import React from 'react'

function Story() {
    return (
        <div className='text-slate-300 text-center bg-slate-800 p-8'>
            <div className='text-slate-300 text-3xl '><b>All the characters I lewd are 18+ and consenting.</b></div>

            <div className='underline text-2xl'><br></br>This page tries to provide more context of the story and lore that I cannot afford to make art of. And I'm not just saying that because I'm trying to comply with the regulations of the ecosystems I exist in. This is really really really (not sarcasm) really the lore :').</div>
            <br></br><br></br>
            <b className='text-center text-2xl underline'>Definitions</b><br></br>
            <br></br>

            <b className='text-xl text-center underline'>Filly  </b><br></br> A <b className='text-pink-200'>filly</b> is not a young horse. It's a phrase I made up one day to label a race I created, who are nicknamed in the world for their unique anatomy. They're called "fillies" because they are small but very fillable (with solids or fluids) relative to their initial volume.
            <br></br><br></br>
            When a filly is consenting, their cervix might loosen up and allow for cervical penetration (which "locks" a stallion's flare in until they cum), or the back of their vaginal walls become stretchier, allowing for the entire cervical wall to be pushed forward, so they can comfotably fit a full horsecock. 
           It was evolutionarily beneficial to give fillies this design feature because they are a small race with a desire to breed. So jokingly, everybody calls them "fillable".
            <br></br> <br></br>
           If you ever believe a character is not consenting, it's because of lore you're mistakedly not seeing yet because I can't find time to animate it (but you can do it for me if you want, if you can match my current level of artistic quality), but trust me, the lore goes as deep as the cervix of a filly in heat.
        
        <br></br><br></br>
        <b className='text-center text-2xl underline'>The Full story</b><br></br>
        <br></br>
        - The page where a pink pony is in a dungeon cell "traps" a cock in her "dungeon": There is some racism in her local province against mating with the filly race, and the adult filly, who is his secret lover, knows that that kind of teasing makes him rock hard. This is not commentary about how there are regulations that stop me from making art of small flatchested girls to the full extent that I could (compared to large big-booba girls), and if you accuse me of such, I might sue you for libel for ruining this totally thriving art business. In fact, I might sue you for negative revenue, since that seems to be how much I am currently making. <div className='text-red-400 font-bold'>THESE REGULATIONS ARE LITERALLY EUGENICS AGAINST GIRLS WITH TOMBOY GENES AND SMALL GUYS LIKE ME WHO GIVE THEM SUCH GENES REEEEEEEEEEEEEEEEEEEE. HOW THE FUCK IS MY FILLY DAUGHTER EXPECTED TO SURVIVE IN THE CUM FARMING MARKET?</div>
        <br></br>
        
        Life is a cum farmer's-market, and only the rich can afford organic. If only you knew how bad things really were for independent cum farmers. We always talk about human rights and the rich, but let me give you the one true human right. The right to produce all-natural, 100% organic cum, by the definition of organic defined by your heart, not the FDAOE. Anything that hinders this in any way, I believe, should be vehemently fought against. We need to seize the means of reproduction.
        
        </div>
        

    )
}

export default Story